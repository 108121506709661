import React, { useEffect, useState } from 'react';
import {
  RouteProps as RouterDomProps,
  Route as RouteDom,
  Redirect,
} from 'react-router-dom';
import { auth } from 'src/firebase/firebase';
import * as paths from '../constants/routes';

interface RouteProps extends RouterDomProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

/*
  isPrivate/IsSigned
  true/true = OK
  true/false = Login redirect
  false/true = Dashboard redirect
  false/false = OK
*/

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((authUser: any) => {
      if (logged && !authUser) {
        setLogged(false);
      } else if (!logged && authUser) {
        setLogged(true);
      }
    });
  }, [logged]);

  return (
    <RouteDom
      {...rest}
      render={({ location }) => {
        return isPrivate === logged ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? paths.START : paths.MAIN_SCREEN,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
