import React from 'react';
import Radio from 'src/components/Radio';
import Select from 'src/components/Select';
import { required } from '../utils/validations';

const RuralProducer: React.FC = () => {
  return (
    <div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="Há quanto tempo você é produtor rural?"
          name="companyTime"
          id="register-companyTime"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Até 1 ano">1 ano</option>
          <option value="2 anos">2 anos</option>
          <option value="3 anos">3 anos</option>
          <option value="4 anos">4 anos</option>
          <option value="5 anos">5 anos</option>
          <option value="6 anos">6 anos</option>
          <option value="7 anos">7 anos</option>
          <option value="8 anos">8 anos</option>
          <option value="9 anos">9 anos</option>
          <option value="10 anos">10 anos</option>
          <option value="11 anos">11 anos</option>
          <option value="12 anos">12 anos</option>
          <option value="13 anos">13 anos</option>
          <option value="14 anos">14 anos</option>
          <option value="15 anos">15 anos</option>
          <option value="16 anos">16 anos</option>
          <option value="17 anos">17 anos</option>
          <option value="18 anos">18 anos</option>
          <option value="19 anos">19 anos</option>
          <option value="20 anos">20 anos</option>
          <option value="21 anos">21 anos</option>
          <option value="22 anos">22 anos</option>
          <option value="23 anos">23 anos</option>
          <option value="24 anos">24 anos</option>
          <option value="25 anos">25 anos</option>
          <option value="26 anos">26 anos</option>
          <option value="27 anos">27 anos</option>
          <option value="28 anos">28 anos</option>
          <option value="29 anos">29 anos</option>
          <option value="30 anos">30 anos</option>
          <option value="Mais de 30 anos">Mais de 30 anos</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="Qual é sua atividade?"
          name="activity"
          id="register-activity"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Produtor Rural">Produtor Rural</option>
          <option value="Produtor Rural e Agroindústria">
            Produtor Rural e Agroindústria
          </option>
          <option value="Agroindústria">Agroindústria</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="Onde você costuma vender seus produtos?"
          name="sellPlace"
          id="register-sellPlace"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Feiras livres">Feiras livres</option>
          <option value="Direto na CEASA">Direto na CEASA</option>
          <option value="Feira de produtores rurais">
            Feira de produtores rurais
          </option>
          <option value="Direto para supermercados, mercadinhos e varejões">
            Direto para supermercados, mercadinhos e varejões
          </option>
          <option value="Distribuidores e revendedores">
            Distribuidores e revendedores
          </option>
          <option value="Porta a porta">Porta a porta</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="Qual o tipo de produto produzido?"
          name="productPlanted"
          id="register-product-planted"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Folhagem">Folhagem</option>
          <option value="Frutas">Frutas</option>
          <option value="Verduras">Verduras</option>
          <option value="Legumes">Legumes</option>
          <option value="Produtos Industrializados">
            Produtos Industrializados
          </option>
          <option value="Soja">Soja</option>
          <option value="Milho">Milho</option>
          <option value="Outros">Outros</option>
        </Select>
      </div>

      <Radio
        className="row"
        label="Você recebe regularmente a visita de técnicos da EMATER?"
        name="ematerVisit"
        id="emater-visit"
        values={['Sim', 'Não']}
        validate={[required]}
      />

      <Radio
        className="row"
        label="Você já participou de algum curso ou treinamento realizado pela
        EMATER?"
        name="ematerTraining"
        id="emater-training"
        values={['Sim', 'Não']}
        validate={[required]}
      />
    </div>
  );
};

export default RuralProducer;
