import { createTextMask, createNumberMask } from 'redux-form-input-masks';

export const normalizeCpf = () => {
  const normalize = createTextMask({
    pattern: '999.999.999-99',
    stripMask: false,
  });
  return {
    ...normalize,
    onChange: (e: any) => normalize.onChange(e),
    onFocus: (e: any) => normalize.onFocus(e),
    onClick: (e: any) => normalize.onClick(e),
    onKeyDown: (e: any) => normalize.onKeyDown(e),
  };
};

export const normalizeCnpj = () => {
  const normalize = createTextMask({
    pattern: '99.999.999.9999-99',
    stripMask: false,
  });
  return {
    ...normalize,
    onChange: (e: any) => normalize.onChange(e),
    onFocus: (e: any) => normalize.onFocus(e),
    onClick: (e: any) => normalize.onClick(e),
    onKeyDown: (e: any) => normalize.onKeyDown(e),
  };
};

export const normalizeCellNumber = () => {
  const normalize = createTextMask({
    pattern: '(99) 9 9999-9999',
  });
  return {
    ...normalize,
    onChange: (e: any) => normalize.onChange(e),
    onFocus: (e: any) => normalize.onFocus(e),
    onClick: (e: any) => normalize.onClick(e),
    onKeyDown: (e: any) => normalize.onKeyDown(e),
  };
};

export const normalizePhoneNumber = () => {
  const normalize = createTextMask({
    pattern: '(99) 9999-9999',
  });
  return {
    ...normalize,
    onChange: (e: any) => normalize.onChange(e),
    onFocus: (e: any) => normalize.onFocus(e),
    onClick: (e: any) => normalize.onClick(e),
    onKeyDown: (e: any) => normalize.onKeyDown(e),
  };
};

export const normalizeCep = () => {
  const normalize = createTextMask({
    pattern: '99999-999',
    stripMask: false,
  });
  return {
    ...normalize,
    onChange: (e: any) => normalize.onChange(e),
    onFocus: (e: any) => normalize.onFocus(e),
    onClick: (e: any) => normalize.onClick(e),
    onKeyDown: (e: any) => normalize.onKeyDown(e),
  };
};

export const normalizeNumber = () => {
  const normalize = createNumberMask({
    allowEmpty: false,
    allowNegative: false,
  });
  return {
    ...normalize,
    onChange: (e: any) => normalize.onChange(e),
    onFocus: (e: any) => normalize.onFocus(e),
  };
};
