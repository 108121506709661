import { db } from './firebase';

export const doCreateUser = (id: string, email: string) => {
  const nowEpoch = new Date();
  return db.ref(`Users/${id}/`).set({
    email,
    uid: id,
    createdDateEpoch: nowEpoch.getTime(),
  });
};

export const onceGetAdmins = (userId: string) =>
  db.ref(`Admins/${userId}`).once('value');

export const onceGetUsers = (userId: string) =>
  db.ref(`Users/${userId}`).once('value');

export const getMens = () => db.ref('Users/Men').once('value');

export const getWomens = () => db.ref('Users/Women').once('value');

export const getContacts = () => db.ref('Contacts').once('value');

export const getAllUsers = () => db.ref('Users');

export const onceGetClass = () => db.ref(`Classes/`).once('value');

export const onceGetTheme = () => db.ref(`Themes/`).once('value');

export const onceGetThemeById = (themeId: string) =>
  db.ref(`Themes/${themeId}`).once('value');

export const oncePremiumUserAndTheme = (userId: string) =>
  db.ref(`PremiumUsers/${userId}`).once('value');

export const refNode = (nodeRef: string) => db.ref(nodeRef);
