import stringSimilarity from 'string-similarity';
import { RAS } from '../../../constants/RAS';

const knownRegions = {
  'Asa Sul': 'Plano Piloto',
  'Asa Norte': 'Plano Piloto',
  'Setor Noroeste': 'Plano Piloto',
  'Setor de Habitações Individuais Sul': 'Lago Sul',
  'Setor de Habitações Individuais Norte': 'Lago Norte',
};

const norte = ['AC', 'AP', 'AM', 'PR', 'RR', 'RO', 'TO'];
const nordeste = ['AL', 'BA', 'CE', 'MA', 'PB', 'PE', 'PI', 'RN', 'SE'];
const centroOeste = ['GO', 'DF', 'MG', 'MS'];
const sudeste = ['ES', 'MG', 'SP', 'RJ'];
const sul = ['PR', 'RS', 'SC'];

export const selectAdmnistrativeRegion = (neighborhood: string) => {
  if (knownRegions[neighborhood]) {
    return knownRegions[neighborhood];
  }
  const best = stringSimilarity.findBestMatch(neighborhood, RAS);
  if (
    best.bestMatch.rating > 0.5 ||
    neighborhood.includes(best.bestMatch.target)
  ) {
    return best.bestMatch.target;
  }
  if (RAS.find(r => neighborhood.includes(r))) {
    return RAS.find(r => neighborhood.includes(r));
  }
  return '';
};

export const selectNationalRegion = (state: string) => {
  if (norte.includes(state)) return 'Norte';
  if (nordeste.includes(state)) return 'Nordeste';
  if (centroOeste.includes(state)) return 'Centro Oeste';
  if (sul.includes(state)) return 'Sul';
  return 'Sudeste';
};
