import React from 'react';
import Radio from 'src/components/Radio';
import Select from 'src/components/Select';
import { required } from '../utils/validations';

const PhysicalPerson: React.FC = () => {
  return (
    <div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="Qual a motivação para você querer abrir seu negócio?"
          name="motivation"
          id="register-motivation"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Alternativa para o desemprego">
            Alternativa para o desemprego
          </option>
          <option value="Única alternativa para sustento">
            Única alternativa para sustento
          </option>
          <option value="Sempre quis ser dono do próprio negócio">
            Sempre quis ser dono do próprio negócio
          </option>
          <option value="Complemento da renda familiar">
            Complemento da renda familiar
          </option>
          <option value="Identifiquei uma boa oportunidade de negócio">
            Identifiquei uma boa oportunidade de negócio
          </option>
          <option value="Para trabalhar com o que eu gosto">
            Para trabalhar com o que eu gosto
          </option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="Qual o setor da economia você pretende atuar?"
          name="industry"
          id="register-industry"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Agronegócio">Agronegócio</option>
          <option value="Comércio">Comércio</option>
          <option value="Indústria">Indústria</option>
          <option value="Serviços">Serviços</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="De onde virá o capital para a abertura do seu negócio?"
          name="capital"
          id="register-capital"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Próprio">Próprio</option>
          <option value="Recisão Trabalhista">Recisão Trabalhista</option>
          <option value="Sócio investidor">Sócio investidor</option>
          <option value="Banco comercial">Banco comercial</option>
          <option value="Financeiras">Financeiras</option>
          <option value="Família">Família</option>
          <option value="Amigos ou Sócio">Amigos ou Sócio</option>
          <option value="Venda de bens">Venda de bens</option>
        </Select>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-6"
          label="Qual a sua maior dificuldade?"
          name="difficulty"
          id="register-difficulty"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Não possuir capital de giro próprio">
            Não possuir capital de giro próprio
          </option>
          <option value="Não ter acesso a crédito bancário">
            Não ter acesso a crédito bancário
          </option>
          <option value="Não possuir experiência como empresário">
            Não possuir experiência como empresário
          </option>
          <option value="Não ter experiência no ramo de atuação">
            Não ter experiência no ramo de atuação
          </option>
          <option value="Não conhecer ferramentas de gestão (Ex: Marketing e Vendas)">
            Não conhecer ferramentas de gestão (Ex: Marketing e Vendas)
          </option>
          <option value="Acesso a capacitação">Acesso a capacitação</option>
          <option value="Não tem tempo para se capacitar">
            Não tem tempo para se capacitar
          </option>
          <option value="Outros">Outros</option>
        </Select>
      </div>

      <Radio
        className="row"
        label="O negócio que você pretende abrir é na mesma área que você possui
              conhecimento?"
        name="businessArea"
        id="business-area"
        values={['Sim', 'Não']}
        validate={[required]}
      />

      <Radio
        className="row"
        label="Você já participou de alguma capacitação empresarial?"
        name="businessTraining"
        id="business-training"
        values={['Sim', 'Não']}
        validate={[required]}
      />

      <Radio
        className="row"
        label="Você já participou de alguma ação do Sebrae?"
        name="sebrae"
        id="sebrae"
        values={['Sim', 'Não']}
        validate={[required]}
      />

      <Radio
        className="row"
        label="Você pretende realizar um plano de negócio antes de abrir o seu
        negócio ou empresa?"
        name="businessPlan"
        id="business-plan"
        values={['Sim', 'Não']}
        validate={[required]}
      />
    </div>
  );
};

export default PhysicalPerson;
