import React, { Component } from "react";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { SIGN_UP_PF, SIGN_UP_PJ, SIGN_UP_MEI, SIGN_UP_MPE, SIGN_UP_EPP, SIGN_UP_PR } from "../../../constants/routes";
import { auth, db } from "../../../firebase";
import * as routes from "../../../constants/routes";
import Loading from "../Loading";
import Vimeo from "@u-wave/react-vimeo";

class Tutorial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    getVideoId = (url) => String(url || '').replace(
        /(https?:)?(\/\/)?(player\.)?vimeo\.com(\/video)?\//gi, ''
    );

    replayVideo = () => {
        this.setState({ isLoading: true }, () => this.componentDidMount());
    }

    render() {
        const { classes } = this.props;

        const opts = {
            playerVars: {
                autoplay: 0,
                rel: 0
            }
        };

        return (
            this.state.isLoading ?
                <Loading />
                :
                <div className="container-fluid">
                    <div className="background">
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: 'center', marginTop: "6%" }}>
                                <span className={classes.title}>Como emitir o certificado</span><br /><br /><br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2" style={{ marginBottom: "6%" }}>
                                <div id="div-main-video" className="embed-responsive embed-responsive-16by9 video-tutorial">
                                    <Vimeo
                                        video={this.getVideoId('https://vimeo.com/435375371')}
                                        autoplay
                                    />
                                    {/* <YouTube
                                        id="main-video"
                                        videoId={this.getVideoId(link)}
                                        opts={opts}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "8%" }}>
                            <div className="col-md-8 col-md-offset-2">
                                <button onClick={() => this.replayVideo()} id="btn-assistir-novamente" type="submit" className="btn btn-default btn-lg col-lg-4 col-md-4 col-xs-12">Assistir novamente</button>
                                <button onClick={() => this.props.history.goBack()} id="btn-continuar" type="submit" className="btn btn-default btn-lg col-lg-4 col-md-4 col-xs-12 cursor-pointer">Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
const styles = {
    root: {
        flex: 1
    },
    body: {
        fonWeight: 100
    },
    title: {
        fontFamily: "Gotham Rounded Book",
        fontSize: "45pt",
        color: '#17549B'
    }
};

export default withStyles(styles)(Tutorial);
