import * as firebase from 'firebase';

const normalConfig = {
  apiKey: 'AIzaSyAgj04efYYpFj4XOTJKow2zfDun_jeVNQY',
  authDomain: 'capacitampe-b1bf8.firebaseapp.com',
  databaseURL: 'https://capacitampe-b1bf8.firebaseio.com',
  projectId: 'capacitampe-b1bf8',
  storageBucket: 'capacitampe-b1bf8.appspot.com',
  messagingSenderId: '606144927339',
};

if (!firebase.apps.length) {
  firebase.initializeApp(normalConfig);
}

const db = firebase.database();
const auth = firebase.auth();

export { db, auth };
