import moment from 'moment';
import { db, auth } from '../firebase/firebase';

export interface CreateUserDTO {
  [key: string]: any;
}

export const updateDisplayName = (displayName: string) =>
  auth.currentUser.updateProfile({ displayName, photoURL: null });

export const userAlredyExist = async (document: string) => {
  const cleanDocument = document.replace(/\D/g, '');

  let user = null;
  if (cleanDocument.length === 11) {
    user = await db
      .ref(`Users/`)
      .orderByChild('cpf')
      .equalTo(document)
      .once('value');
  } else {
    user = await db
      .ref(`Users/`)
      .orderByChild('cnpj')
      .equalTo(document)
      .once('value');
  }

  if (user && user.val()) {
    return true;
  }

  return false;
};

export const createUser = async (formData: CreateUserDTO, uid: string) => {
  const { email, name } = formData;
  await db.ref(`Users/${uid}/`).set({
    email,
    uid,
    createdDateEpoch: new Date().getTime(),
  });

  const signupSnapshot = await db.ref('FreeSignupConfig').once('value');
  const signupConfig = signupSnapshot.val() as {
    expirationAmount: string;
    activated: boolean;
    expirationByDate: boolean;
    expirationDate: string;
    registerAmount: string;
    registerLimit: string;
  };
  let days = parseInt(signupConfig.expirationAmount, 10);
  if (!signupConfig.activated) {
    days = 180;
  }
  const userData = {
    ...formData,
    expiration_date: moment().add(days, 'days').format(),
    clientId: 'DV6Op3v71SQQuVfqplcxyoJkFz92',
  };

  await db.ref(`Users/${uid}`).update(userData);
  if (name) {
    updateDisplayName(name);
  }
  // this.sendSms(email);
  let promotionalSignup = true;
  if (signupConfig.activated) {
    if (signupConfig.expirationByDate) {
      const now = moment(moment().format('DD/MM/YY'), 'DD/MM/YY');
      const expirationDate = moment(signupConfig.expirationDate, 'DD/MM/YY');
      if (now > expirationDate) {
        promotionalSignup = false;
      }
    }

    if (
      parseInt(signupConfig.registerAmount, 10) >=
      parseInt(signupConfig.registerLimit, 10)
    ) {
      promotionalSignup = false;
    }

    if (promotionalSignup) {
      db.ref('FreeSignupConfig').set({
        ...signupConfig,
        registerAmount: parseInt(signupConfig.registerAmount, 10) + 1,
      });
    }
  }
};
