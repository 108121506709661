import React from 'react';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import {
  normalizeCellNumber,
  normalizeCnpj,
  normalizeCpf,
  normalizePhoneNumber,
} from '../utils/normalizations';
import { validCnpj, validCpf, required } from '../utils/validations';

interface Props {
  type: string;
}

export interface CnpjData {
  companyName: string;
  cep: string;
  complement: string;
  number: string;
  CNAE: string;
}

const PersonalData: React.FC<Props> = ({ type }) => {
  return (
    <>
      <div className="row">
        <Input
          className="form-group col-md-4"
          type="date"
          name="registerDate"
          id="register-date"
          disabled
          label="Data do Cadastro"
          validate={[required]}
        />

        <Input
          className="form-group col-md-10"
          label="Nome Completo"
          name="name"
          id="register-name"
          placeholder=" Ex: Ricardo Vieira Lopes Mendes"
          validate={[required]}
        />
      </div>
      {type.includes('PF') ? (
        <div className="row">
          <Input
            className="form-group col-md-5"
            label="CPF"
            name="cpf"
            id="register-cpf"
            placeholder=" Ex: 000.000.000-00"
            validate={[required, validCpf]}
            {...normalizeCpf()}
          />
        </div>
      ) : (
        <div>
          <div className="row">
            <Input
              className="form-group col-md-5"
              label="Nome da Empresa"
              name="companyName"
              id="register-compoany-name"
              placeholder=" Ex: Nome da Empresa"
              validate={[required]}
              disabled
            />

            <Input
              className="form-group col-md-6"
              label="CNPJ"
              name="cnpj"
              id="register-cnpj"
              placeholder=" Ex: 00.000.000/0000-00"
              validate={[required, validCnpj]}
              {...normalizeCnpj()}
            />
          </div>
          <div className="row">
            <Input
              className="form-group col-md-5"
              label="CNAE principal"
              name="CNAE"
              id="register-CNAE"
              validate={[required]}
              disabled
            />
            <Input
              className="form-group col-md-6"
              label="Nome Completo do Responsável"
              name="owner"
              id="register-owner"
              placeholder=" Ex: Ricardo Vieira Lopes Mendes"
              validate={[required]}
            />
          </div>
          <div className="row">
            <Input
              className="form-group col-md-5"
              label="CPF do responsável"
              name="ownerCPF"
              id="register-cpf"
              placeholder=" Ex: 000.000.000-00"
              validate={[required, validCpf]}
              {...normalizeCpf()}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="form-row">
          <Input
            className="form-group col-md-5"
            label="Celular"
            name="phone"
            id="register-phone"
            placeholder=" Ex: (31)99999-9999"
            validate={[required]}
            {...normalizeCellNumber()}
          />

          <Input
            className="form-group col-md-6"
            label="Telefone Fixo"
            name="telephone"
            id="register-phone-number"
            placeholder=" Ex: (31)9999-9999"
            {...normalizePhoneNumber()}
          />
        </div>
      </div>
      <div className="row">
        <Select
          className="form-group col-md-5"
          label="Sexo"
          name="sex"
          id="register-sex"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Masculino">Masculino</option>
          <option value="Feminino">Feminino</option>
        </Select>
        <Input
          className="form-group col-md-6"
          label="Idade"
          type="number"
          name="age"
          id="register-age"
          placeholder=" Ex: 18 anos"
          validate={[required]}
        />
      </div>
      <div className="row">
        <Select
          className="form-group col-md-5"
          label="Formação Acadêmica"
          name="schooling"
          id="register-schooling"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Analfabeto">Analfabeto</option>
          <option value="Primeiro-Grau">Primeiro-Grau</option>
          <option value="Segundo-Grau">Segundo-Grau</option>
          <option value="Superior">Superior</option>
          <option value="Graduação">Graduação</option>
          <option value="Pós-Graduação">Pós-Graduação</option>
          <option value="Mestrado">Mestrado</option>
          <option value="Doutorado">Doutorado</option>
        </Select>
        <Select
          className="form-group col-md-6"
          label="Por qual canal você pretende acessar esta plataforma?"
          name="accessChannel"
          id="register-access-channel"
          validate={[required]}
        >
          <option value="">Selecione</option>
          <option value="Celular">Celular</option>
          <option value="Web">Web</option>
          <option value="Tablet">Tablet</option>
        </Select>
      </div>
    </>
  );
};

export default PersonalData;
