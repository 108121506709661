import React, { Component } from "react";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { SIGN_UP } from "../../../constants/routes";
import { auth, db } from "../../../firebase";
import * as routes from "../../../constants/routes";
import Loading from "../Loading";
import Vimeo from "@u-wave/react-vimeo";


const MEI_VIDEO = "https://vimeo.com/340061573"
const MPE_VIDEO = "https://vimeo.com/340067256"
const EPP_VIDEO = "https://vimeo.com/340067256"
const PR_VIDEO  = "https://vimeo.com/340061573"
const PF_VIDEO  = "https://vimeo.com/340061573"

class Tutorial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            isLoading: true
        }
    }

    componentWillMount() {
        if (this.props.propsState && this.props.propsState.review) {
            // @TODO
            if (auth.getAuthUser()) {
                let userUid = auth.getAuthUser().uid;
                db.refNode(`Users/${userUid}/type`).once("value").then((userType) => {
                    if (userType.val()) {
                        if (userType.val() == "PF") {
                            this.setState({ link: "https://vimeo.com/340061573" });
                        } else {
                            this.setState({ link: "https://vimeo.com/340067256" });
                        }
                    } else {
                        this.props.history.push(routes.MAIN_SCREEN);
                    }
                }).catch((error) => {
                    // console.log("Error", error);
                });
            }
        } else {
            if (auth.getAuthUser()) {
                this.props.history.push(routes.MAIN_SCREEN);
            }
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false });
        this.setState({signUpLink: SIGN_UP});
        this.setState({accountType: ""});
        if (this.props.propsState && this.props.propsState.type && this.props.propsState.accountType) {
            if (this.props.propsState.type == "MEI") {
                this.setState({signUpLink: SIGN_UP});
                this.setState({ link: MEI_VIDEO });
            }
            else if (this.props.propsState.type == "MPE") {
                this.setState({signUpLink: SIGN_UP});
                this.setState({ link: MPE_VIDEO });
            }
            else if (this.props.propsState.type == "EPP") {
                this.setState({signUpLink: SIGN_UP});
                this.setState({ link: EPP_VIDEO });
            }
            else if (this.props.propsState.type == "PR") {
                this.setState({signUpLink: SIGN_UP});
                this.setState({ link: PR_VIDEO });
            }
            else {
                this.setState({ link: PF_VIDEO });
            }
            this.setState({accountType: this.props.propsState.accountType});
        } else {
            if (!this.props.propsState || !this.props.propsState.review) {
                this.props.history.push(routes.MAIN_SCREEN);
            }
        }
    }

    getVideoId = (url) => String(url || '').replace(
        /(https?:)?(\/\/)?(player\.)?vimeo\.com(\/video)?\//gi, ''
    );

    replayVideo = () => {
        this.setState({ isLoading: true }, () => this.componentDidMount());
    }

    render() {
        const { classes } = this.props;

        const opts = {
            playerVars: {
                autoplay: 0,
                rel: 0
            }
        };

        return (
            this.state.isLoading ?
                <Loading />
                :
                <div className="container-fluid">
                    <div className="background">
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: 'center', marginTop: "6%" }}>
                                <span className={classes.title}>Tutorial</span><br /><br /><br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2" style={{ marginBottom: "6%" }}>
                                <div id="div-main-video" className="embed-responsive embed-responsive-16by9 video-tutorial">
                                    {this.state.link &&
                                        <Vimeo
                                            video={this.getVideoId(this.state.link)}
                                            autoplay
                                           onEnd={() => {
                                            const userUid = auth.getAuthUser().uid;
                                            db.refNode(`Users/${userUid}/`).update({tutorial: true})
                                           }}

                                        />
                                    }
                                    {/* <YouTube
                                        id="main-video"
                                        videoId={this.getVideoId(link)}
                                        opts={opts}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "8%" }}>
                            <div className="col-md-8 col-md-offset-2">
                                <button onClick={() => this.replayVideo()} id="btn-assistir-novamente" type="submit" className="btn btn-default btn-lg col-lg-4 col-md-4 col-xs-12">Assistir novamente</button>
                                <Link to={{ pathname: this.state.signUpLink, state: { accountType: this.state.accountType } }}>
                                    <button id="btn-continuar" type="submit" className="btn btn-default btn-lg col-lg-4 col-md-4 col-xs-12 cursor-pointer">Continuar</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
const styles = {
    root: {
        flex: 1
    },
    body: {
        fonWeight: 100
    },
    title: {
        fontFamily: "Gotham Rounded Book",
        fontSize: "45pt",
        color: '#17549B'
    }
};

export default withStyles(styles)(Tutorial);
