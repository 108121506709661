import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import MainHeader from "../MainHeader";
import MainFooter from "../MainFooter";
import withAuthorization from "../Session/withAuthorization";
import { auth, db } from "../../../firebase";
import VID_QUEST from "../Images/Group 6.png";
import VID_COMP from "../Images/Group 7.png";
import VID_ASSIS from "../Images/Group 8.png";
import Loading from "../Loading";
import $ from "jquery";
import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	ADDITIONAL_LESSONS,
	START,
	QUIZ,
	VIEWED_LESSONS,
	PF_LESSONS,
	TUTORIAL,
	CERTIFICATE
} from "../../../constants/routes";
import moment from "moment";
import _ from "lodash";
import Cookies from "universal-cookie";
import whatsapplogo from "../Images/whatsapplogo.png"
import instagramlogo from "../Images/instagramlogo.png"
import emaillogo from "../Images/Blue-email-icon.png"

class MainScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: auth.getAuthUser(),
			isLoading: true,
			smsConfirm: "",
			isValid: false,
			resendLoading: false,
			userData: {},
			cookies: new Cookies()
		};
	}

	async componentDidMount() {
    let userUid = auth.getAuthUser().uid;
    console.log('userUid', userUid)
		await db.refNode(`Users/${userUid}/`)
			.once("value")
			.then(userData => {
				const _userData = userData.val();
				if (_userData) {
					this.setState({ userData: _userData });
				} else {
					auth
						.doSignOut()
						.then(() => {
							this.props.history.push(START);
						})
				}
			});

		const purchaseKey = this.state.cookies.get('purchaseKey')

		if (purchaseKey) {
			// 	if(_.size(themesOfCart)>0){
			// 		_.each(themesOfCart, cartThemes =>{
			// 			_.each(cartThemes,carData=>{
			// 				if(carData.coursesCost){
			// 					_.map(carData.themesArray, async uid => {
			// 						const theme = await db
			// 						.refNode("Themes/" + uid)
			// 						.once("value")
			// 						.then(theme => {
			// 							return theme.val();
			// 						});
			// 						await db.refNode("PremiumUsers/" + currentUser.uid + "/" + uid).set({
			// 							themeId: uid,
			// 							themeName: theme.themeName,
			// 							payamentdate: moment().valueOf(),
			// 							paymentCost: carData.coursesCost,
			// 							daysAvailable: carData.daysAvailable,
			// 							themeImgUrl:theme.themeImgUrl
			// 						});
			// 					});
			// 				}

			// 				if(carData.themeCost){
			// 					db.refNode("PremiumUsers/" + currentUser.uid + "/" + carData.uid).set(
			// 						{
			// 							themeId: carData.uid,
			// 							themeName: carData.themeName,
			// 							payamentdate: moment().valueOf(),
			// 							paymentCost: typeof !carData.themeCost
			// 							? "R$ 0,00"
			// 							: carData.themeCost,
			// 							daysAvailable: !carData.daysAvailable ? "":carData.daysAvailable,
			// 							themeImgUrl:carData.themeImgUrl
			// 						},
			// 					);
			// 				}
			// 			})
			// 		})
			// 	}

			// 	if (cardInformation) {
			// 		if (!cardInformation.currentUser) cardInformation.currentUser == this.state.currentUser;
			// 		if (!cardInformation.theme.themeCost && !cardInformation.theme.coursesCost)
			// 		cardInformation.theme.themeCost == "R$ 0,00";
			// 		if (!cardInformation.theme.themeName && cardInformation.theme.coursesName) {
			// 			_.map(cardInformation.theme.themeData.themesArray, async uid => {
			// 				const theme = await db
			// 				.refNode("Themes/" + uid)
			// 				.once("value")
			// 				.then(theme => {
			// 					return theme.val();
			// 				});
			// 				await db.refNode("PremiumUsers/" + currentUser.uid + "/" + uid).set({
			// 					themeId: uid,
			// 					themeName: theme.themeName,
			// 					payamentdate: moment().valueOf(),
			// 					paymentCost: cardInformation.theme.coursesCost,
			// 					daysAvailable: cardInformation.theme.daysAvailable,
			// 					themeImgUrl:theme.themeImgUrl
			// 				});
			// 			});
			// 		} else if(cardInformation.theme.uid){
			// 			// console(cardInformation)
			// 			db.refNode("PremiumUsers/" + currentUser.uid + "/" + cardInformation.theme.uid).set(
			// 				{
			// 					themeId: cardInformation.theme.uid,
			// 					themeName: cardInformation.theme.themeName,
			// 					payamentdate: moment().valueOf(),
			// 					paymentCost: typeof !cardInformation.theme.themeCost
			// 					? "R$ 0,00"
			// 					: cardInformation.theme.themeCost,
			// 					daysAvailable: !cardInformation.theme.daysAvailable ? "":cardInformation.theme.daysAvailable,
			// 					themeImgUrl:cardInformation.theme.themeImgUrl
			// 				},
			// 			);
			// 		}
			// 		// swal({
			// 		// 	text:
			// 		// 	"Parabéns! A sua compra foi finalizada com sucesso!",
			// 		// 	icon: "success",
			// 		// 	dangerMode: true,
			// 		// });
			// 	}
			db.refNode(`Purchases/`)
				.orderByChild('activationKey')
				.equalTo(purchaseKey)
				.limitToLast(1)
				.once('value')
				.then(snapshot => {
					const purchases = snapshot.val();
					let purchase = undefined;
					if (purchases) {
						purchase = {
							...purchases[Object.keys(purchases)],
							key: Object.keys(purchases)
						}
					}
					if (purchase && purchase.hotmart.email == this.state.userData.email) {

						if (purchase.userUid) {
							this.setState({ userBought: false });
							swal({
								text: 'Essa compra já foi finalizada! Caso você não tenha feito a finalização ou esteja com problemas, entre em contato em contato@capacitampe.com.br',
								icon: "error",
								buttons: {
									cancel: false,
									confirm: true,
								},
								dangerMode: true,
							});
							return;
						}

						// Add local storage info, so the main screen recognizes the
						// user purchase
						// localStorage.setItem('purchaseKey', purchase.key);

						// var userBought = 'CHOOSE';
						// localStorage.setItem('pkg', pkg);

						db.refNode(`Purchases/${purchaseKey}/`)
							.update({ userUid: userUid })
							.then(() => {
								// @TODO should check if the PremiumUsers info was really
								// added
								this.setState({ resendLoading: false }, () => {
									swal({
										text:
											'Parabéns! A sua compra foi finalizada com sucesso!',
										icon: "success",
										dangerMode: true,
									});
								});
							});

					} else {
						this.setState({ userBought: false });
						swal({
							text: 'Compra não encontrada. Por favor, confira os dados ou entre em contato em contato@capacitampe.com.br',
							icon: "error",
							buttons: {
								cancel: false,
								confirm: true,
							},
							dangerMode: true,
						});
					}
				}).catch(() => {
					this.setState({ userBought: false });
					swal({
						text: "Ocorreu algum erro!",
						icon: "error",
						buttons: {
							cancel: false,
							confirm: true,
						},
						dangerMode: true,
					});
				});
		}
		this.setState({ isLoading: false });
	}

	resendCode = () => {
		this.setState({ resendLoading: true }, () => {
			let userUid = auth.getAuthUser().uid;
			db.refNode(`Users/${userUid}/`)
				.once("value")
				.then(userData => {
					if (userData.val()) {
						let user = userData.val();
						const uniqueCode = Math.floor(1000000 + Math.random() * 9000000);
						const COUNTRY_CODE = "55";
						const number = user.phone;
						const contactPhone = number
							.replace(" ", "")
							.replace("(", "")
							.replace(")", "")
							.replace("-", "");
						const smsText =
							"Seja bem vindo ao Capacita MPE! Para confirmar os seus dados digite o seguinte código: " +
							uniqueCode;
						fetch("https://api-rest.zenvia360.com.br/services/send-sms", {
							method: "POST",
							headers: {
								accept: "application/json",
								"content-type": "application/json",
								authorization: "Basic YmVlc3RhcnQ6VXVLRTNDY3Q5OA==",
								"cache-control": "no-cache",
							},
							body: JSON.stringify({
								sendSmsRequest: {
									aggregateId: "",
									msg: smsText,
									id: uniqueCode,
									from: "Capacita MPE",
									to: COUNTRY_CODE + contactPhone,
									callbackOption: "FINAL",
								},
							}),
						})
							.then(response => response.json())
							.then(responseData => {
								db.refNode(`Users/${userUid}/`)
									.update({ smsCode: uniqueCode, validated: false })
									.then(() => {
										this.setState({ resendLoading: false }, () => {
											swal({
												text: "Código reenviado com sucesso!",
												icon: "success",
												buttons: {
													cancel: false,
													confirm: true,
												},
												dangerMode: true,
											});
										});
									});
							})
							.catch(error => { });
					}
				});
		});
	};

	smsConfirm = () => {
		const code = this.state.smsConfirmCode;
		let userUid = auth.getAuthUser().uid;
		db.refNode(`Users/${userUid}/smsCode`)
			.once("value")
			.then(smsCode => {
				if (smsCode.val() == code) {
					db.refNode(`Users/${userUid}/`)
						.update({ validated: true })
						.then(() => {
							swal({
								text: "Conta verificada com sucesso!",
								icon: "success",
								buttons: {
									cancel: false,
									confirm: true,
								},
								dangerMode: true,
							});
							$("#smsClose").click();
							this.setState({ isValid: true });
						})
						.catch(() => {
							swal({
								text: "Erro ao confirmar código!",
								icon: "error",
								buttons: {
									cancel: false,
									confirm: true,
								},
								dangerMode: true,
							});
						});
				} else {
					swal({
						text: "Erro ao confirmar código!",
						icon: "error",
						buttons: {
							cancel: false,
							confirm: true,
						},
						dangerMode: true,
					});
				}
			})
			.catch(() => {
				swal({
					text: "Erro ao confirmar código!",
					icon: "error",
					buttons: {
						cancel: false,
						confirm: true,
					},
					dangerMode: true,
				});
			});
	};

	render() {
		// console.log('this.state.userData.pkg', this.state.userData.pkg);
		const { classes } = this.props;
		/*
		if (!this.state.isValid) {
			return (
				<div className="container-fluid">
					<u id="toggleSms" data-toggle="modal" data-target="#smsModal"></u>
					<div id="smsModal" className="modal fade" tabindex="-1" role="dialog" data-backdrop="static">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<button id="smsClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								<div className="modal-body">
									<form id="password-login">
										<div className="form-group">
											<div className="col-md-12">
												<label for="password-forget" className="login-label">Enviamos um código para o seu celular, por favor informe-o abaixo para continuar</label>
											</div>
											<div className="col-md-12">
												<input type="text" id="password-forget" className="form-control placeholder" placeholder="*********"
													onChange={(event) =>
														this.setState({
															smsConfirmCode: event.target.value,
														})
													} />
												<br />
											</div>
										</div>
										<div className="row">
											<div className="col-md-10 col-md-offset-1 text-center">
												<button id="btn-enviar" className="btn btn-default btn-lg clickable" onClick={() => this.smsConfirm()}>SALVAR</button>
											</div>
										</div>
										<div className="row">
											<div className="col-md-10 col-md-offset-1 text-center code-text">
												{!this.state.resendLoading ?
													<a className="clickable" onClick={() => this.resendCode()}>Não recebeu o código? Clique aqui para reenviar</a>
													:
													<div className="text-center">
														<CircularProgress size={40} className={classes.progress} />
													</div>
												}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {*/
		return this.state.isLoading ? (
			<Loading />
		) : (
				<div className="container-fluid">
					<div className="background">
						<MainHeader logado={this.state.currentUser ? true : false} />
						<div
							className="col-md-8 col-md-offset-2 col-xs-12"
							style={{ textAlign: "left", marginTop: "1%" }}>
							<span className={classes.text}>
								Boas vindas, {this.state.currentUser.displayName},
						</span>
							<br />
							<span className={classes.textBlue}>o que você quer assistir?</span>
							<br />
							<br />
							<br />
						</div>
						<div className="col-md-10 col-md-offset-1">
							<div className="row">
								{this.state.userData.pkg === 'FREE' &&
									<div className="col-xs-12 col-md-2"></div>
								}
								<div className="col-xs-12 col-md-4"
									style={this.state.userData.pkg === 'FREE' ? { display: 'none' } : {}}
								>
									<Link to={PF_LESSONS}>
										<img
											id="VID_QUEST"
											className="img-responsive center-block image-reduced"
											src={VID_QUEST}
											alt="Video aulas questionario PF"
										/>
									</Link>
								</div>
								<div className="col-xs-12 col-md-4 mobile-div">
									<Link to={ADDITIONAL_LESSONS}>
										<img
											id="VID_COMP"
											className="img-responsive center-block image-reduced"
											src={VID_COMP}
											alt="Video aulas complementares"
										/>
									</Link>
								</div>
								<div className="col-xs-12 col-md-4 mobile-div">
									<Link to={VIEWED_LESSONS}>
										<img
											id="VID_ASSIS"
											className="img-responsive center-block image-reduced"
											src={VID_ASSIS}
											alt="Emissão de Certificado"
										/>
									</Link>
								</div>
							</div>
							<div className="row" style={{ marginTop: "5%" }}>
								<div className="col-md-10">
									<a href="http://www.capacitampe.com.br/" target="_blank">
										<button
											id="btn-saiba-mais"
											className="btn btn-default btn-lg col-lg-3 col-md-3 col-xs-12 col-md-offset-1 clickable">
											Site
										</button>
									</a>

									<Link
										to={{
											pathname: TUTORIAL,
											state: {
												review: true,
												currentUser: this.state.currentUser,
											},
										}}>
										<button
											id="btn-tutorial"
											className="btn btn-default btn-lg col-lg-3 col-md-3 col-xs-12 col-md-offset-1 clickable">
											Tutorial
										</button>
									</Link>

									<Link
										to={{
											pathname: CERTIFICATE,
											state: {
												review: true,
												currentUser: this.state.currentUser,
											},
										}}>
										<button
											id="btn-certificado"
											className="btn btn-default btn-lg col-lg-3 col-md-3 col-xs-12 clickable">
											Saiba como emitir<br /> o certificado
										</button>
									</Link>
								</div>
							</div>
							<div className="row">
								<div id="containerlogos">
									<a href="http://www.instagram.com/capacitampe" target="_blank">
										<img
											className="logo"
											src={instagramlogo}
											alt="instagramlogo"
										/>
									</a>
									<a href="https://api.whatsapp.com/send/?phone=%2B5561991879097&text=Ol%C3%A1%2C+sou+aluno+do+capacita+mpe%2C+poderia+me+ajudar?&app_absent=0&lang=pt_br" target="_blank">
										<img
											className="logo"
											src={whatsapplogo}
											alt="whatsapplogo"
										/>
									</a>

									<a href="mailto:contato@capacitampe.com.br">
										<img className="logo"
											src={emaillogo}
											alt="emaillogo" />
									</a>

								</div>
							</div>
						</div>
						<MainFooter />
					</div>
				</div>
			);
		//}


	}
}

const styles = {
	root: {
		flex: 1,
	},
	body: {
		fonWeight: 100,
	},
	text: {
		fontFamily: "Gotham Rounded Medium",
		wordBreak: "break-all",
		fontSize: "30pt",
		color: "#505050",
	},
	textBlue: {
		fontFamily: "Gotham Rounded Book",
		fontSize: "20pt",
		color: "#4A90E2",
	},
};

const authCondition = authUser => !!authUser;
// MainScreen = withAuthorization(authCondition)(MainScreen);
MainScreen = withStyles(styles)(MainScreen);
export default MainScreen;
