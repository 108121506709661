import React, { useCallback, useState } from 'react';
import {
  InjectedFormProps,
  reduxForm,
  setSubmitFailed,
  setSubmitSucceeded,
} from 'redux-form';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/store';
import { SignUpState } from 'src/store/modules/SignUp/types';
import Loading from 'src/components/Loading';
import { createUser, userAlredyExist } from 'src/services/userService';
import swal from 'sweetalert';
import { createAccount } from 'src/services/authService';
import {
  startLoadingAction,
  stopLoadingAction,
} from 'src/store/modules/SignUp/actions';
import { useHistory } from 'react-router-dom';
import { SIGN_IN } from 'src/constants/routes';
import SignUpComponent from './component';
import Tutorial from './components/Tutorial';
import SelectType from './components/SelectType';

const SignUpContainer: React.FC<
  InjectedFormProps<{ registerDate: string }>
> = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector<AppState, SignUpState>(state => state.signUp);

  const [type, setType] = useState<string | null>(null);
  const [tutorialWatched, setTutorialWatched] = useState(false);

  const watchedTutorial = useCallback(() => {
    setTutorialWatched(true);
  }, []);

  const submit = useCallback(
    async (data: Record<string, any>) => {
      dispatch(startLoadingAction());
      const userExist = await userAlredyExist(
        type.includes('PF') ? data.cpf : data.cnpj,
      );

      if (!userExist) {
        const { email, password } = data;
        const { uid } = await createAccount({ email, password });

        const dataWithoutPassword = { ...data };
        delete dataWithoutPassword.password;
        delete dataWithoutPassword.passwordConfirm;

        try {
          await createUser(dataWithoutPassword, uid);
          dispatch(stopLoadingAction());
          await swal({
            text: 'Cadastro realizado com sucesso!',
            icon: 'success',
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: false,
          });
          dispatch(setSubmitSucceeded('signUp'));
          history.push(SIGN_IN);
        } catch (err) {
          dispatch(stopLoadingAction());
          await swal({
            text: 'Desculpe, ocorreu um erro ao cadastrar sua conta!',
            icon: 'error',
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: true,
          });
          dispatch(setSubmitFailed('signUp'));
        }
      } else {
        dispatch(stopLoadingAction());
        await swal({
          text: `${
            type.includes('PF') ? 'CPF' : 'CNPJ'
          } já cadastrado! Por favor, escolha outro!`,
          icon: 'error',
          buttons: {
            cancel: false,
            confirm: true,
          },
          dangerMode: true,
        });
        dispatch(setSubmitFailed('signUp'));
      }
    },
    [dispatch, type, history],
  );

  return !type ? (
    <SelectType selectType={setType} />
  ) : !tutorialWatched ? (
    <Tutorial type={type} watchedTutorial={watchedTutorial} />
  ) : (
    <>
      {loading && <Loading />}
      <div style={{ display: loading ? 'none' : 'block' }}>
        <SignUpComponent {...props} type={type} submit={submit} />
      </div>
    </>
  );
};

export default reduxForm({
  form: 'signUp',
  enableReinitialize: true,
  initialValues: {
    registerDate: moment().format('YYYY-MM-DD'),
  },
})(SignUpContainer);
